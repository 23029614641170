@import '../../variables.scss';
.Container{
    @media (min-width:$md) {
        margin-top:10px;
    }
}

.TextContainer{
    margin: 10px 10px;
    text-align: center;

}

.DialogTitle{
    text-align: center;
    font: $font__impactHeading;
    color: $primaryColor;
    margin-top: 5px;
}

.Image{
    width:250px;
    margin:10px;
    max-width: 40vw;
    height:auto;
    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering:   -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
}

.MediumImage{
    @extend .Image;
    width:400px;
}

.LargeImage{
    @extend .Image;
    width:500px;
}

