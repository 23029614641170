@import '../../variables.scss';

.MainContainer {
  min-width: $cardMinWidth;
    display: flex;
    flex-direction: column;
    padding:$metricCardPadding
}


.Header {
  font: $font__heading;
  color: $resmedBlack;
  text-align: left;
  margin-bottom: 20px;
}

.RadioGroup {
    display: flex;
    flex-direction: column;
    font: $font__bodyRegular;
    color: $resmedBlack;
}

.Radio {
    color: $primaryColor;
    &:hover {
      background-color: $secondaryColor !important;
    }
  }
  
.Radio__checked {
    color: $primaryColor !important;
    &:hover {
      background-color: $secondaryColor !important;
    }
}