@import '../../variables.scss';

.valueContainer {
    display:flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 12px;
    margin-bottom: 10px;
}

.FeetInchesTextField {
    text-align: left;
    margin-right: 10px;
    font-size: 20px;
    max-width: 80px;
}

.Unit {
    margin-right: 28px;
    margin-left: 12px;
    font: $font__unit;
    color: $resmedBlack;
}

.ErrorText  {
    font: $font__subText;
    color: $errorRed;
    text-align: left;
}

.FtControl {
    min-width: 120;
}

.DropdownInputLabel {
    margin-left: 14px;
    top: -10px;
}
.DropdownInput {
margin: 4px;
}