@import '../../variables.scss';

.Paper {
   background: transparent;
   box-shadow: none;
   max-width: 400px;
    @media (max-width: $sm) {
        height:80vh;
        padding: 0px;
    }
    &:hover{
        transform: scale(1.0);
        box-shadow: none;
    }
} 

.Dialog{
    min-width: 300px;
    box-shadow: none;
    justify-content: center;
    text-align: center;
    border-radius: $borderRadius_card;
    background: $cardGradient;
    padding-bottom: 15px;
}

.DialogBackground{
    color: transparent;
    background-color: transparent;
    box-shadow: none;
    margin: 0%;
}

.DialogContent{
  color:$resmedBlack;
  font: $font__subText;
  text-align: left;
  margin-bottom: 15px;
}

.CheckboxLabel{
    color:$resmedBlack;
    font: $font__subText;
    text-align: center;
    padding-right: 8px;
    @media (max-width: $xs) {
        font: $font__extraSmall;
        }
}

.SubHeader {
    text-align: center;
    font: $font__lightSubHeading;
    color: $primaryColor;
    margin-bottom: 15px;
}

.Header {
    text-align: center;
    font: $font__impactHeading;
    color: $primaryColor;
    margin-top: 5px;
}

.CloseButtonContainer{
    @media (min-width: $sm) {
        display: none;
    }
}

.AgreeButton{
    width:250px;
    margin-top: 5px;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: $xs) {
        width:150px;
    }
}