@import '../../variables.scss';

.Card {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: left;
    min-width: $cardMinWidth;
    &.error {
        border-color: $errorRed;
        border-style: solid;
        &.warning {
            border-color: $errorRed;
            border-style: solid;
        }
    }
    &.warning {
        border-color: $warningForeground;
        border-style: solid;
    }
    padding:$metricCardPadding;
}

.TextField {
    text-align: left;
    margin-right: 10px;
    font-size: 20px;
    min-width: 90px;
    max-width: 120px;
    border-color: $primaryColor !important;
    &:focus{
        border-color:$primaryColor !important;
    }
    &:input{
        border-color:$primaryColor;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
}

.Focus {
    border-color: $primaryColor;
}

.Unit {
    margin-right: 12px;
    font: $font__unit;
    color: $resmedBlack;
}

.FeetInchesTextField {
    text-align: left;
    margin-right: 10px;
    font-size: 20px;
    max-width: 80px;
}

.Container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 5px;
    padding-left: 2px;
}

.valueContainer {
    display:flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 24px;
    padding-bottom: 8px;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Header {
    font: $font__heading;
    color: $resmedBlack;
    text-align: left;
    margin-bottom: 10px;
}

.SubText {
    font: $font__bodyRegular;
    color: $resmedBlack;
    text-align: left;
}

.ErrorText  {
    font: $font__subText;
    color: $errorRed;
    text-align: left;
}
.WarningText  {
    font: $font__subText;
    color: $resmedBlack;
    text-align: left;
    display: flex;
    align-items: center;
}

.WarningIcon  {
    fill: $warningForeground;
    text-align: left;
    height: 10px;
    width:5px;
    margin-right: 10px;
}


.HelpButton:hover{
    filter: brightness(140%);
    cursor: pointer;
    transition: all 0.3s;
}

.ButtonGroup{
    border-radius: 10px;
    margin-left: auto;
    margin-right: 0;
    box-shadow: none;
}

.Icon {
    width: 50px!important;
    height: 50px;
}

.Popover{
    padding: 10px 10px 10px 10px;
}

.UnitButton {
    border-radius: 10px;
    background-color: $resmedWhite;
    &.selected {
        background-color: $primaryColor;
        color: $resmedWhite;
    }
}

.SliderContainer {
    margin-top: 12px;
}
