@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
$desktopNavHeight: 64px;
$mobileNavHeight: 48px;
$maxPageWidth: 760px;

$font__extraSmall: 200 12px 'Open Sans';
$font__bodyRegular: 400 16px 'Open Sans';
$font__subText: 500 14px 'Open Sans';
$font__bodyBold: 700 16px 'Open Sans';
$font__heading: 700 30px 'Open Sans';
$font__impactHeading: 700 35px 'Open Sans';
$font__lightHeading: 300 20px 'Open Sans';
$font__lightSubHeading: 300 20px 'Open Sans';
$font__subHeading: 700 24px 'Open Sans';
$font__textField: 400 30px 'Open Sans';
$font__unit: 400 20px 'Open Sans';



// ------ Transition Times ------
$navigationTransitionTime: 200ms;

// ------ Borders ------
$borderRadius__button: 30px;
$borderRadius__container: 4px;

// ------ Cards ------
$cardMinWidth: 300px;

// ------ Breakpoints ------
$xs: 320px;
$sm: 480px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

// ------ Colour Palette ------

//All colours pass WCAG AA accessibility standards.


//primary color options:
    //res-color-001-500
    $primaryColor: #1788C3;
    //res-dark-lime-400
    $primaryColor: #287e26;
    //res-color-010-500
    $primaryColor: #6f2c91;
    //res-color-004-500
    $primaryColor: #0077C0;

//default primary colour for now
$primaryColor: #0077C0;

//res-grey 100
$secondaryColor: #f0eeee;
$buttonSecondaryColor: #555555;

$resmedWhite: rgb(255, 255, 255);
$resmedBlack: rgb(0,0,0);
$cardGrey: #f3f3f3;
$deselectedGrey: rgb(189, 188, 188);

$cardGradient: linear-gradient(180deg,$resmedWhite ,#f9f6f6 );
$cardBoxShadowElevated: 0px 4px 40px rgba(0, 0, 0, 0.300);
$cardBoxShadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
$borderRadius_card: 7px;
$metricCardPadding: 20px 20px 20px 20px;

$errorRed: #f15463;
$warningBackground: #ffedc7;
$warningForeground:#ffcc60;
$warningText:#ffa11f;
