@import '../../variables.scss';

.ButtonContainer{
    left: -14px;
    bottom: -10px;
}
.HelpButton{
    fill:$deselectedGrey;
    color: black;
}

.HelpText {
    fill:$primaryColor;
    padding: 10px;
    max-width: 280px;
    overflow-wrap: break-word;
}