@import '../../variables.scss';

.CloseButton{
   color: $deselectedGrey;
   position: relative;
   padding-right: 10px;
   @media (min-width: $md) {
       display: none;
   }

}
.CloseButtonPersist{
    color: $deselectedGrey;
   position: relative;
}

.CloseButtonContainer{
    text-align: right;
    margin:0px;
}