@import '../../variables.scss';

.Card {
    display: flex;
    justify-content: space-between;
    flex-direction: column;   
    min-width: $cardMinWidth;
    align-items: left;
    &.error {
        border-color: $errorRed;
        border-style: solid;
    }
    padding:$metricCardPadding;
}

.Container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 6px;
    @media (min-width:$md) {
        margin-top: 15px;   
    }
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Header {
    font: $font__heading;
    color: $resmedBlack;
    text-align: left;
    margin-bottom: 5px;
}


.ButtonGroup{
    border-radius: 10px;
    margin-left: auto;
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: none;
}

.Icon {
    width: 50px!important;
    height: 50px;
}

.Popover{
    padding: 10px 10px 10px 10px;
}

.UnitButton {
    border-radius: 10px;
    text-transform: none;
    background-color: $resmedWhite;
    &.selected {
        background-color: $primaryColor;
        color: $resmedWhite;
    }
}

.SliderContainer {
    margin-top: 40px;
    padding-bottom: 2px;
}

.Slider {
    color: $primaryColor;
}
.FtSelector {
    padding-right: 40px;
}