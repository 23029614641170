@import '../../variables.scss';

.RMPaper {
    border-radius: $borderRadius_card;
    background: $cardGradient;
    padding: 5px 10px;
    max-width: 300px;
    margin: 16px auto;
    box-shadow: $cardBoxShadow;
    transition: all 0.2s;
    @media (min-width: $md) {
        margin: 8px auto;
        &:hover{
            box-shadow: $cardBoxShadowElevated;
            transform: scale(1.02);
            cursor:default;
        }
    }
}

