@import './variables.scss';

.App {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
  align-items: center;
  height:auto;
  // iPad Pro additional margin
  @media only screen 
  and (min-width: 1024px) 
  and (max-width: 1030px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    position: relative;
    top: 30%;
    -webkit-transform: translateY(10%);
    -ms-transform: translateY(10%);
    transform: translateY(10%);
    margin-bottom: 20px;
  }
}

.TitleContainer{
  display: flex;
  justify-content: space-between;
  vertical-align: baseline; 
  margin-top: 10px;
}

.Legal {
  text-transform: none;
  margin-right: 20px;
  @media (max-width:$sm){
    margin-right: 10px;
  }
}
.Help {
  text-transform: none;
  float:left;
}
.WordMark {
  max-width: 200px;
  margin-left: 40px;
  height: auto;
  @media (max-width:$sm){
    max-width: 150px;
    margin-left: 20px;
  }
}
.Logo {
  max-width: 80px;
  height:auto;
  margin-top: 20px;
}

.Version {
  font: $font__extraSmall;
  color: $deselectedGrey;
}

.ButtonContainer {
  right: 20px;
  @media (max-width:$sm){
    margin-left: 10vw;
  }
}

.SnackbarButtonLabel {
   text-transform: none
}

