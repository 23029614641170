@import '../../variables.scss';

.Accordion{
    box-shadow: none;
    text-align: left;
}

.Question{
    font-weight: 700;
}
.Answer{
    font-weight: 400;
    padding-left: 50px;
}

.ExpandIcon{
    fill:$primaryColor;
}

.SubHeader {
    text-align: center;
    font: $font__lightSubHeading;
    color: $primaryColor;
    margin-bottom: 15px;
}

.Header {
    text-align: center;
    font: $font__impactHeading;
    color: $primaryColor;
    margin-top: 5px;
    @media (max-width: $md) {
        margin-top: 20px;
    }
}

.Container{
    max-width: 90vw;
}

.MarkDown{
    margin:0px;
    padding: 0px;
}
