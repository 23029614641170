@import '../../variables.scss';

.MetricListCard {
    min-width: $cardMinWidth;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px 20px 20px;
}

.CardTitle {
    font: $font__heading;
    color: $resmedBlack;
    margin-top: 5px;
    margin-bottom: 10px;
}

.List {
    padding-inline-start: 0px;
    margin: 0;
}

.MetricRow {
    display: flex;
    margin-bottom:-3px;
    padding-left: 7px;
    font: $font__bodyRegular;
    color: $resmedBlack;
    &.name {
        text-align: left;
        margin-right: auto;
    }
}

.horizontalLine {
    margin: 8px;
    border-color: $deselectedGrey;
    height: 1px;
    background-color: $secondaryColor;
    border: none;
}


.horizontalLine:last-child {
    display: none;
 }

 .SummaryCardPaper {
    min-width: 240px;
    justify-content: center;
    background: white;
    text-align: center;
    max-width: 50vw;
    max-lines: 350px;
    padding: 10px 10px 20px 10px;
    box-shadow: none;
    @media (min-width: $md) {
        &:hover{
            box-shadow: none;
            transform: none;
            transition: none;
        }
    }
    @media (max-width: $xs) {
        min-width: 200px;
    }
} 

.TargetVAValue {
    text-align: center;
    font: $font__impactHeading;
    color: $primaryColor;
    margin-bottom: 15px;
}

.TargetVATitle {
    text-align: center;
    font: $font__lightHeading;
    color: $buttonSecondaryColor;
}

.TargetVaWarning {
    color: $resmedBlack;
    margin: 10px;
    text-align: left;
    display: flex;
    align-items: center;
    font: $font__subText;
    border-radius: $borderRadius_card;
    padding:10px 10px 10px 10px;
    max-width: 300px;
    margin-bottom: 10px;
    border-style: solid;
    border-color: $warningForeground;
    border-radius: $borderRadius_card;
    border-width: 2px;
}

.WarningIcon {
    text-align: left !important;
    fill: $warningForeground;
    margin-right: 15px;
}

.MetricTitle {
    font:$font__bodyRegular;
}
.MetricValue {
    font:$font__lightHeading;
}

.DialogMetricValue {
    @extend .MetricValue;
    @media (max-width:$sm) {
        font:$font__subText;
    }
}

.DialogMetricTitle {
    @extend .MetricTitle;
    @media (max-width:$sm) {
        font:$font__subText;
    }
}


.CloseButtonContainer{
    @media (min-width: $md) {
        display: none;
    }
}
