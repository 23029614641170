@import '../../variables.scss';
.CopyButton{
    padding-top: 15px;
    padding-bottom: 15px;
    margin-right:0px;
    fill: $primaryColor;
    &:hover{
        filter: brightness(110%);
        cursor: pointer;
        transition: all 0.3s;
}
    @media (max-width: $md) {
        display: none;
    }
    @media screen and (max-width:$xl) and (orientation:landscape) {
        display: none;
        }
}

.ToolTip{
    font:$font__bodyRegular;
}