@import '../../variables.scss';


.Paper {
    margin: auto;
   text-align: center;
    box-shadow: none;
      z-index: 1;
      padding-bottom: 15px;
      padding-top: 20px;
      @media (min-width: $lg) {
        @media (max-width: $xl){
           width:$maxPageWidth;  
        }
      }
      @media (min-width:$xs) {
        background: $secondaryColor;
        padding-bottom: 30px;
        margin-bottom: 0px;
      }
      @media (min-width: $md) {
        width: 720px;   
        padding-top: 20px;
        background-color: $cardGrey;
        border-radius:$borderRadius_card;
        padding-left: 20px;
        padding-right: 20px;
          transform:scale(85%);
          -webkit-transform:scale(0.85, 0.85);
        }
}
.FlexContainer{
  box-shadow: none;
  @media (min-width: $md) {
    display: flex;
    flex-wrap: wrap;
    max-width: $maxPageWidth;
    }
}

.Grid {
    margin: auto;
    background-color: $primaryColor;
    margin: 12px;
    padding: 12px;
    border-radius: $borderRadius__container;
    display: flex;
    box-shadow: none;
    flex-direction: column;
    @media (min-width: $md) {
        width: 450px;
        }
        @media (min-width: $lg) {
          width: $maxPageWidth;
        }
}

.Header {
    font: $font__heading;
    color: $secondaryColor;
}

.CalculateButtonGroup {
  z-index:0;
  padding-top: 20px;
}

.CalculateButton {
  text-transform: none;
  margin: auto;
  box-shadow: none;
  height:45px;
  padding-right:15px;
  padding-left: 15px;
  border-radius: $borderRadius__button;
  &:hover{
    transform: scale(1.03);
    box-shadow: none;
    background-color: $primaryColor;
}
  
}
.ResetButton {
  text-transform: none;
  color: $errorRed;
  background-color: $resmedWhite;
  border-style: solid;
  border-width: 2px;
  border-radius: $borderRadius__button;
  margin-right: 20px;
  height:45px;
  padding-right:15px;
  padding-left: 15px;
  box-shadow: none;
  &:hover{
    transform: scale(1.03);
    background-color: $resmedWhite;
    box-shadow: none;
}
}

.DialogBackground{
    color: transparent;
    background-color: transparent;
    box-shadow: none;
    margin: 0%;
}

.DialogContent{
  max-width: 100vw;
  max-height: 100vh;
  padding:0px;
}