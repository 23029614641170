@import '../../variables.scss';

.Paper {
    max-height:80vh;
    max-width: 90vw;
    justify-content: center;
    text-align: center;
    border-radius: $borderRadius_card;
    background: transparent;
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: none;
    padding: 10px;
    margin: 0;
     @media (max-width: $sm) {
         height:80vh;
     }
     &:hover{
         transform: scale(1.0);
         box-shadow: none;
     }
} 

.DialogContent{
    font: $font__subText;
    max-width: 70vw;
}

.DialogBackground{
    padding:none;
}